export const getSchoolIdFromUrl = (xUrl: string) => {
  const url = new URL(xUrl);

  if (url.pathname.startsWith('/admin/')) {
    const schoolId = url.pathname.match(/\/admin\/([a-zA-Z0-9_-]+)\/?/)?.[1];
    return schoolId;
  }

  if (url.pathname.startsWith('/schools/')) {
    const schoolId = url.pathname.match(/\/schools\/([a-zA-Z0-9_-]+)\/?/)?.[1];
    return schoolId;
  }

  if (url.pathname.startsWith('/api/schools/')) {
    const schoolId = url.pathname.match(/\/api\/schools\/([a-zA-Z0-9_-]+)\/?/)?.[1];
    return schoolId;
  }

  const pathnames = url.pathname.split('/');

  const schoolId = pathnames[1];

  if (!isSchoolIsPossible(schoolId)) {
    return null;
  }

  return schoolId;
};

export const isSchoolIsPossible = (schoolId: string) => {
  if (schoolId.length < 2) {
    return false;
  }

  if (schoolId.includes('.')) {
    return false;
  }

  if (schoolId.includes(' ')) {
    return false;
  }

  return true;
};
